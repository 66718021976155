import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

import {
  Link
} from "react-router-dom";

function FeatureCardButtons(props){
  return(
    <div className='FeatureCardButtons FlexRow'>
      <FeatureCardButton featureIndex={props.featureIndex} buttonNo={0} {...props}/>
      <FeatureCardButton featureIndex={props.featureIndex} buttonNo={1} {...props}/>
      <FeatureCardButton featureIndex={props.featureIndex} buttonNo={2} {...props}/>
    </div>
  )
}

function FeatureCardButton(props){
  const [CSS, setCSS] = useState("FeatureCardButton");

  useEffect(() => {
    if (props.featureIndex === props.buttonNo){
      setCSS("FeatureButton FlexCenter Pointer FeatureButtonActive");
    }else{
      setCSS("FeatureButton FlexCenter Pointer");
    }
  }, [props.featureIndex]);

  return(
    <div className={CSS} onClick={() =>{
      props.setFeatureIndex(props.buttonNo)
    }}></div>
  )
}

export default memo(FeatureCardButtons)