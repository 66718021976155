import React, { useState, useEffect, useCallback, useRef, memo, forwardRef } from 'react';
import { URLify } from './Utils';
import { Helmet } from 'react-helmet';

import Footer from './Footer';
import AuthorProfile from './AuthorProfile';
import TopBar from './TopBar';
import PageNotFound from './PageNotFound';
import { useParams } from "react-router-dom";
import LandingMenu from './LandingMenu';
import { fadeIn } from './Utils';
import { fadeOut } from './Utils';
import FadeInDiv from './FadeInDiv';
import Nudge from './Nudge';
import { removeNudge } from './Utils';
import ContentParagraph from './ContentParagraph';
import {
  useNavigate
} from "react-router-dom";
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-S5TJYSXKZ2"

function ActiveContent(props){
  const [error, setError] = useState(false)
  const fadeInRef = useRef(null);
  const nudgeRef = useRef(null);
  const textRef = useRef(null);

  let {id} = useParams()
  
  useEffect(() => {
    if (id !== "" && id !== URLify(props.activeStory)){
      props.setActiveStory(id)
    }
  }, [id])

  const getContent = async () => {
    try{
      fadeIn(fadeInRef)
      let res = await fetch(props.domain + `c?s=${id.replace(/\s/g,"-")}`)
      res = await res.json()
      fadeOut(fadeInRef)
      removeNudge(nudgeRef)
      props.setActiveStoryContent(res)
    } catch{
      setError(true)
    }   
  }

  const shouldCall = useRef(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    async function fetchAll(){
      await getContent()
      window.scrollTo(0, 0)
    }
    document.body.style.overflow = "visible";
    if (shouldCall.current){
      // analytics
      ReactGA.initialize(TRACKING_ID);
      const path = window.location.pathname + window.location.search
      ReactGA.send({ hitType: "pageview", page: path });

      fetchAll()
      shouldCall.current = false
    }
  }, [])

  const goToAuthor = () => {
    window.scrollTo(0, document.body.scrollHeight);
  }

  return(
    <>
    {error &&
      <PageNotFound/>
    }
    {!error &&
    <>
      <Helmet>
        <meta name="description" content={`${props.activeStoryContent[0].metadata.title} by ${props.activeStoryContent[0].metadata.author} - ${props.activeStoryContent[0].metadata.blurb}`}/>
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <LandingMenu {...props} MainColor={"OffWhiteBackground"} SidebarColor={"DarkPrimary"} useGradient={true}/>  

      <FadeInDiv spinner={true} ref={fadeInRef} color={"OffWhiteBackground"} speed={"VerySlowTransition"}/>
      <DecoPattern/>
      <div className="ContentContainer FlexColumn AutoMargin MainColumnWidth">
        <div className="ContentMetadata">
          <div className="ContentTitle SansSerif Uppercase HighlightPrimary">{props.activeStoryContent[0].metadata.title}</div>
          <div className="ContentByLine SansSerif Pointer UnderlineHover HighlightHover" style={{maxWidth: "300px"}} onClick={goToAuthor}>{props.activeStoryContent[0].metadata.author}</div>
        </div>
        <div ref={textRef} className="TextContainer AutoMargin">
        {
          props.activeStoryContent[0].text.map((para, index) => {
            return <ContentParagraph key={`para-${index}`}para={para} index={index} activeStoryContent={props.activeStoryContent} isPoem={Boolean(props.activeStoryContent[0].metadata.category === "Poetry")}/>
          })
        }  
        </div> 
        
        <AuthorProfile author={props.activeStoryContent[1]} name={props.activeStoryContent[0].metadata.author} maxWidth={"650px"}/>
        <EndDeco/>
      </div>
      <ProgressBar ref={textRef}/>
      <Footer/>
    </>  
    }
    </>
  )
}


function DecoPattern(){
  const [randomNum, setRandomNum] = useState(Math.ceil(Math.random(1) * 2))

  return(
    <div className="FlexCenter FullWidth NoPointerEvents">
    {randomNum === 1 &&
      <div className="DecoPattern" style={{marginTop:'-18px', backgroundImage: "url(/images/headerdesign1.svg)"}}/>
    }
    {randomNum === 2 &&
      <div className="DecoPattern" style={{marginTop:'-18px', backgroundImage: "url(/images/headerdesign2.svg)"}}/>
    }
    </div>
  )
}

function EndDeco(){
  return(
    <div className="FullWidth FlexCenter EndDeco">
    <svg width="400" height="40" viewBox="0 0 304 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.64111 24.4724H140.966M140.966 24.4724L144.436 21.01M140.966 24.4724L134.027 17.5477M144.436 21.01L147.905 17.5477M144.436 21.01L140.966 17.5477M144.436 21.01L151.44 28L158.576 21.01M147.905 17.5477L144.436 14.0854M147.905 17.5477H140.966M144.436 14.0854L140.966 10.6231M144.436 14.0854L140.966 17.5477M144.436 14.0854L147.709 10.8191M140.966 10.6231H8.64111M140.966 10.6231L134.027 17.5477M134.027 17.5477H0M134.027 17.5477H140.966M147.709 10.8191L151.44 7.09548L155.303 10.8191M147.709 10.8191L145.156 8.27136L151.44 2L157.856 8.27136L155.303 10.8191M147.709 10.8191L151.44 14.5427L155.303 10.8191M294.359 24.4724H162.045M162.045 24.4724L158.576 21.01M162.045 24.4724L168.984 17.5477M158.576 21.01L155.106 17.5477M158.576 21.01L162.045 17.5477M155.106 17.5477L158.576 14.0854M155.106 17.5477H162.045M158.576 14.0854L162.045 10.6231M158.576 14.0854L162.045 17.5477M158.576 14.0854L155.303 10.8191M162.045 10.6231H294.359M162.045 10.6231L168.984 17.5477M168.984 17.5477H304M168.984 17.5477H162.045" stroke="#E29C2D" stroke-opacity="1"strokeWidth="1.5"/>
    </svg>
    </div>
  )
}

const ProgressBar = forwardRef((props, ref) => {
  const [progressPercent, setProgressPercent] = useState(0)

  const activeRef = useRef(null)
  const inactiveRef = useRef(null)

  useEffect(() => {
      setProgressPercent(getScrollPercentage(ref))
      const onScroll = () => {
        setProgressPercent(getScrollPercentage(ref))
      }
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('reisze', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      window.addEventListener('resize', onScroll, { passive: true });
      return () => {
        window.removeEventListener('scroll', onScroll);
        window.removeEventListener('reisze', onScroll);
      }
  }, [props,ref]);

  return(
    <>
      <div ref={inactiveRef} className="ProgressInactive">
        <div ref={activeRef} style={{width: `${progressPercent}%`}} className="ProgressActive"></div>
      </div>
    </>
  )
})

function getScrollPercentage(ref){
  if (!ref.current) return
  const divStart = document.documentElement.scrollTop + ref.current.getBoundingClientRect().top
  const divHeight = ref.current.scrollHeight
  const pageHeight = document.documentElement.clientHeight
  const bottomOfPage = document.documentElement.scrollTop + pageHeight

  const startAmountToScroll = divHeight - (pageHeight - divStart)
  const remainingAmountToScroll = divHeight - (bottomOfPage - divStart)

  return 100 - ((remainingAmountToScroll / startAmountToScroll) * 100)
}

export default ActiveContent