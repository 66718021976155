import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

import Cross from './Cross';
import { openInNewTab } from './Utils';
import FormalButton from './FormalButton';

import {
  Routes,
  Link,
} from "react-router-dom";

function SubscribeDialog (props){

  return(
    <div className="DialogContainer FlexCenter">
      <div className ="Dialog OffWhiteBackground">
        <div className="DialogTopBar"><Cross mobileOnly={false} height={"40"} width={"40"} callBack={props.setDisplaySubscribe}/></div>
        <div className="FlexCenter DialogChildContainer">
        <div className="AutoMargin">
          <div className="SansSerif HighlightPrimary Heading DialogHeading">Support us</div>
          <div className="DialogText">The best way to help us pay writers more is to become a patron.</div>
          <div className="FullWidth FlexCenter FlexColumn AutoMargin">
            <DonateButton src="/images/patreon_logo.png" link="https://www.patreon.com/cornice"/>
          </div>
          <div className="DialogText">You can also make a donation on PayPal or Buy Me a Coffee.</div>
          <div className="FullWidth FlexCenter FlexColumn AutoMargin">
            <DonateButton src="/images/paypal_logo.png" link="https://www.paypal.com/donate/?hosted_button_id=YCP5VAWHDGV4W"/>
            <DonateButton src="/images/bmc_logo.png" link="https://www.buymeacoffee.com/cornice"/>
          </div>
          <div className="DialogText">Follow us on Twitter to know when we publish new content.</div>
          <div className="FullWidth FlexCenter FlexRow AutoMargin" style={{maxWidth:'55%'}}>
            <DonateButton src="/images/icon-twitter.svg" link="https://www.twitter.com/cornicemag" maxHeight={'50px'}/>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

function DonateButton(props){
  return (
    <>
      <img src={props.src} style={{maxHeight:`${props.maxHeight ? props.maxHeight : ''}`}} class="DonateButton Pointer" onClick={()=>{openInNewTab(props.link)}}/>
    </>
  )
}


export default memo(SubscribeDialog)