import React, { useState, useEffect, useCallback, useRef, memo, forwardRef } from 'react';
import { Helmet } from 'react-helmet';

import Footer from './Footer';
import TopBar from './TopBar';
import { fadeIn, fadeOut, removeNudge, URLify, unURLify, getThumbnail } from './Utils';
import {Link, useSearchParams} from "react-router-dom";
import LandingMenu from './LandingMenu';
import Nudge from './Nudge';
import FadeInDiv from './FadeInDiv';
import FadeInDivLocal from './FadeInDivLocal';


function Search (props){
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchResults, setSearchResults] = useState([])
  const [rawQuery, setRawQuery] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [isName, setIsName] = useState(0)
  const fadeInRef = useRef(null)
  const nudgeRef = useRef(null)
  const shouldCall = useRef(true) // This makes sure we only fetch once, bug with remounts in React 18
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const query = (props.params) ? props.params : searchParams.get("q")
    const isName = (props.params) ? props.params : searchParams.get("n")
    if (query !== ""){
      setSearchQuery(query)
      setRawQuery(query)
    }
    if (isName === '1'){
      setIsName(1)
    }
  }, [searchParams, props.params])

  useEffect(() => {
    async function fetchResults(){
      window.scrollTo(0, 0)
      setSearchResults([])
      if (searchQuery === "" || !searchQuery) return;
      setIsLoading(true)
      let res = await fetch(props.domain + `s?q=${searchQuery}&n=${isName}`)
      res = await res.json()
      setSearchResults(res)
      setIsLoading(false)
      window.scrollTo(0, 0)
      setSearchParams({q: searchQuery, n: isName});
    }
    fetchResults()
  }, [searchQuery])

  useEffect(() => {
    document.body.style.overflow = "visible";
    fadeOut(fadeInRef)
    setTimeout(function() {
      removeNudge(nudgeRef)
    }, 20);
    setTimeout(function() {
      window.scrollTo(0, 0)
    }, 50);
    
  }, [])

  return(
    <>
    <Helmet>
      <meta name="description" content="Search for a story on Cornice by author, title, genre, or keyword." />
      <meta name="robots" content="index, follow"/>
    </Helmet>
    <LandingMenu {...props} MainColor={"DarkPrimary"} SidebarColor={"DarkPrimary"}/>
    <div className="BasicPage MainColumnWidth ContentContainer">
      <FadeInDiv spinner={false} ref={fadeInRef} color={"OffWhiteBackground"} speed={"SlowTransition"}/>
      <SearchBar setSearchQuery={setSearchQuery} setRawQuery={setRawQuery} setIsName={setIsName}/>
      {isLoading &&
        <div className="CenterText SearchMessage">Loading...</div>
      }
      {searchResults.length === 0 && searchQuery === "" && !isLoading &&
        <div className="CenterText SearchMessage">Search by author, genre, date...</div>
      }
      {searchResults.length === 0 && searchQuery !== "" && !isLoading &&
        <div className="CenterText SearchMessage">No results found{searchQuery ? " for \"" + unURLify(searchQuery) + "\"" : ''}.</div>
      }
      {searchResults.length > 0 && !isLoading &&
        <>
        {searchResults.length > 1 &&
          <div className="CenterText SearchMessage">{searchResults.length} results found for {isName === 1 ? 'author' : ''} "{isName === 1 ? nameify(rawQuery) : unURLify(rawQuery)}"</div>
        }
        {searchResults.length === 1 &&
          <div className="CenterText SearchMessage">{searchResults.length} result found for {isName === 1 ? 'author' : ''} "{isName === 1 ? nameify(rawQuery) : unURLify(rawQuery)}"</div>
        }
        <SearchResultsGrid searchResults={searchResults}/>
        </>
      }
    </div>
    <Footer/>
    </>
  )
}

function nameify(query){
  let words = query.split("-")
  let names = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
  return names.join(" ")
}

function SearchBar(props){
  const [input, setInput] = useState("")
  const inputStateRef = React.useRef(input); // Need to use a ref to mirror the state value or the event listener will only ever have access to just the first value

  const onSubmit = (event) => {
    event.preventDefault();
  }

  const handleChange = (event) => {
    setInput(event.target.value)
    inputStateRef.current = event.target.value;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    sendSubmit();
  }

  const sendSubmit = () => {
    let queryString = inputStateRef.current.replace(/^\s+|\s+$/g, '')
    queryString = queryString.replace(/\s{2,}/g, ' ')
    props.setRawQuery(queryString)
    queryString = queryString.replace(/\s+/g, '-')
    props.setSearchQuery(queryString.toLowerCase())
    props.setIsName(0)
  }

  const shouldCall = useRef(true) // This makes sure we only fetch once, bug with remounts in React 18

  useEffect(() => {
    const handlePress = event => {
      if (event.keyCode === 13){
        sendSubmit();
      }
    };

    // Make sure we don't add two add event listeners
    if (shouldCall.current){
      window.addEventListener('keydown', handlePress);
      shouldCall.current = false
    }

    return () => {
      window.removeEventListener('keypress', handlePress);
    };
  }, []);

  return(
    <>
    <div className="AutoMargin FlexRow SearchBar FullWidth">
      <form className="SearchForm FlexRow AutoMargin FullWidth" onSubmit={onSubmit}>
        <input className="SearchInput SansSerif DarkText OffWhiteBackground" type="text" name="query" value={input} onChange={handleChange}/>
        <div className="SearchButton Pointer FlexCenter AlignCenter SansSerif OffWhiteBackground" onClick={handleSubmit}><div>Search</div></div>
      </form>
    </div>
    </>
  )
}


function SearchResultsGrid(props){
  return(
    <>
    {props.searchResults.length > 0 &&
      props.searchResults.map(metadata => {
        return <SearchResult metadata={metadata} key={metadata.title}/>
      })
    }
    </>
  )
}

function SearchResult(props){
  const imageRef = useRef(null)
  const titleRef = useRef(null)
  const nudgeRef = useRef(null)
  const fadeInRef = useRef(null)

  useEffect(() => {
    fadeOut(fadeInRef)
    setTimeout(function() {
      removeNudge(nudgeRef)
    }, 20);
  }, [])

  return (
    <>
      <Nudge ref={nudgeRef} speed={"SlowTransition"}/>
      <Link to={`/content/${URLify(props.metadata.title)}`} className='FlexRow SearchResult' 
      onMouseEnter={()=>{
        imageRef.current.classList.remove("FullOpacity")
        imageRef.current.classList.add("PartialOpacity")
        titleRef.current.classList.add("Underline")
      }}
      onMouseLeave={()=>{
        imageRef.current.classList.add("FullOpacity")
        imageRef.current.classList.remove("PartialOpacity")
        titleRef.current.classList.remove("Underline")
      }}>
        <FadeInDivLocal ref={fadeInRef} color={"OffWhiteBackground"} speed={"SlowTransition"}/>
        <div className="SearchImageContainer NoMobile">
          <img ref={imageRef} src={"images/" + getThumbnail(props.metadata.img)} className="SearchImage LeftBorder"/>
          <div></div>
        </div>
        <div className='SearchMetadata FlexColumn FullWidth SpaceBetween FullHeight'>
          <div>
            <div ref={titleRef} className='SearchTitle SansSerif HighlightPrimary'>{props.metadata.title}</div>
            <div className='SearchBlurb' >{props.metadata.blurb}</div>
          </div>
          <div className="FlexRow SpaceBetween SearchByLine">
            <div>By {props.metadata.author}</div>
            {props.metadata.category === "Poetry"
              ? <div>Poem</div>   
              : <div>{props.metadata.readTime} minute read</div>
            }
            
          </div>
        </div>
      </Link>
    </>
  )
}


export default memo(Search)