import React, { useEffect, useRef, memo, useState, forwardRef } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'


function ContentParagraph(props){
  const headingPattern = /^#+\s*/;
  const [contentType, setContentType] = useState('content');
  const [content, setContent] = useState(props.para)

  useEffect(() => {
    if (props.index === 0 && props.isPoem === true) {
      controls.start("visible");
    }
  }, []);

  useEffect(() => {
    if (props.para.match(headingPattern)){
      setContentType('heading');
      setContent(props.para.replace(headingPattern, ""))
      return;
    }
    else if (props.para === "~~~"){
      setContentType('paraBreak');
      return;
    }
    else if (props.para === "~"){
      setContentType('stanzaBreak');
      return;
    }
    else if (props.isPoem) {
      setContentType('poem');
      return;
    }
    else {
      setContentType('content');
      return;
    }
  }, [props.para, props.isPoem]);

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: 0.1, 
    rootMargin: '80px 0px',
  });

  const controls = useAnimation();

  useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
  }, [controls, inView, ref]);

  const variants = {
    hidden: { y: 100, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  return (
    <AnimatePresence>
      {(function() {
        switch (contentType) {
          case 'paraBreak':
            return <motion.div 
              ref={ref} 
              initial="hidden"
              animate={controls}
              variants={variants}
              viewport={{ once: true }}
              transition = {{
                type: "spring",
                bounce: 0.2,
                duration: 1.0,
            }}className="FullWidth FlexCenter" style={{marginTop:"2em", marginBottom:"1em"}}><img className="parabreak" src="/images/parabreak.svg"/></motion.div>;
          case 'stanzaBreak':
            return <motion.div 
              ref={ref} 
              initial="hidden"
              animate={controls}
              variants={variants}
              viewport={{ once: true }}
              transition = {{
                type: "spring",
                bounce: 0.2,
                duration: 1.0,
            }}className="FullWidth FlexCenter" style={{marginBottom:"2em"}}></motion.div>;
          case 'poem':
            return <motion.div 
              ref={ref} 
              initial={"hidden"}
              animate={controls}
              variants={variants}
              viewport={{ once: true }}
              transition = {{
                type: "spring",
                bounce: 0.2,
                duration: 1.0,
            }}className="PoemPara">{props.para}</motion.div>;
          case 'heading':
            return <motion.div
              ref={ref} 
              initial="hidden"
              animate={controls}
              variants={variants}
              viewport={{ once: true }}
              transition = {{
                type: "spring",
                bounce: 0.2,
                duration: 1.0,
            }}><h2 className="SansSerif">{content}</h2></motion.div>;
          case 'content':
          default:
            return props.index === 0
              ? <>
              <motion.div
              ref={ref} 
              initial="hidden"
              animate={controls}
              variants={variants}
              viewport={{ once: true }}
              transition = {{
                type: "spring",
                bounce: 0.2,
                duration: 1.0,
              }} className="FirstLetter Serif PrimaryHighlight">{props.activeStoryContent[0].text[0][0]}</motion.div> 
              <motion.div
                ref={ref} 
                initial="hidden"
                animate={controls}
                variants={variants}
                viewport={{ once: true }}
                transition = {{
                  type: "spring",
                  bounce: 0.2,
                  duration: 1.0,
              }} className="ContentPara">{trimParagraph(props.para,props.index)}</motion.div>
            </>
              : <motion.div
              ref={ref} 
              initial="hidden"
              animate={controls}
              variants={variants}
              viewport={{ once: true }}
              transition = {{
                type: "spring",
                bounce: 0.2,
                duration: 1.0,
            }} className="ContentPara">{props.para}</motion.div>;
        }
      })()}
    </AnimatePresence>
  );
}

function trimParagraph (para, index) {
  if (index !== 0) return para;
  let arr = para.split("");
  arr.shift();
  return arr.join("");
};


export default ContentParagraph