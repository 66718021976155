import React, { useState, useEffect, useCallback, useRef, memo, forwardRef } from 'react';
import { Helmet } from 'react-helmet';

import Footer from './Footer';
import TopBar from './TopBar';
import LandingMenu from './LandingMenu';
import { fadeIn, removeNudge } from './Utils';
import { fadeOut } from './Utils';
import FadeInDiv from './FadeInDiv';
import Nudge from './Nudge';

const headingPattern = /^\#+(?= [^#]+$)/

function BasicPage (props){
  const [basicPageContent, setBasicPageContent] = useState({paras: [""]})
  const fadeInRef = useRef(null)
  const nudgeRef = useRef(null)

  const getContent = useCallback(async () => {
    fadeIn(fadeInRef)
    let res = await fetch(props.domain + `b?p=${props.targetPage.replace(/\s/g,"-")}`)
    res = await res.json()
    setBasicPageContent(res) 
    fadeOut(fadeInRef)
    removeNudge(nudgeRef)
  }, [props.targetPage])

  useEffect(() => {
    document.body.style.overflow = "visible";
    getContent()
    window.scrollTo(0, 0)
  }, [props.targetPage])

  /*
  const shouldCall = useRef(true)

  useEffect(() => {
    if (shouldCall.current){
      getContent()
      window.scrollTo(0, 0)
      shouldCall.current = false
    }
  }, [props.targetPage])
  */

  return(
    <>
    <Helmet>
      <meta name="description" content="Cornice is the best place to read new writing online." />
      
    </Helmet>
    <div className="FullWidth DarkPrimary FullHeight">
      <LandingMenu {...props} MainColor="DarkPrimary" SidebarColor="OffWhiteBackground" landing={false} useGradient={true}/>
      <div className="BasicPage ContentContainer OffWhiteText">
        <FadeInDiv spinner={false} ref={fadeInRef} color={"DarkPrimary"} speed={"FastTransition"}/>
        {
          basicPageContent.paras.map((para, i) => {
            if (headingPattern.test(para)){
              const level = para.match(headingPattern)[0].length
              const heading = para.replace(headingPattern, "")
              return <>
              <div key={`para${i}`} className={`ContentHeading h${level} HighlightPrimary SansSerif`}>{heading}</div>
              </>
            }else{
              return <div key={`para${i}`} className="ContentPara" dangerouslySetInnerHTML={{__html: para}}></div>
            }
          })
        }
      </div>
      <Footer/>
    </div>
    </>
  )
}

export default memo(BasicPage)