import logo from './logo.svg';
import './App.css';
import TopBar from './TopBar';
import MainContent from './MainContent';

import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { Helmet } from 'react-helmet';

import ActiveContent from './ActiveContent';
import ContentGrid from './ContentGrid';
import BasicPage from './BasicPage';
import PageNotFound from './PageNotFound';
import Search from './Search';
import LandingPage from './LandingPage';
import ContributorPage from './ContributorPage';

import {
  Routes,
  Route,
  useSearchParams
} from "react-router-dom";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-S5TJYSXKZ2"; // OUR_TRACKING_ID

//const domain = 'http://localhost:8080/'
const domain = 'https://cornicemag.nn.r.appspot.com/'

function App() {
  const [page, setPage] = useState("home")
  const [activeStory, setActiveStory] = useState("") // Title of story
  const [activeStoryContent, setActiveStoryContent] = useState([{metadata:{title:""}, text:[""]}, {}]) // Full data for story
  const [displaySubscribe, setDisplaySubscribe] = useState(false)
  const [mailLink, setMailLink] = useState("https://mailchi.mp/46a74af6b016/subscribe")
  const [donateLink, setDonateLink] = useState("https://www.paypal.com/donate/?hosted_button_id=YCP5VAWHDGV4W")
  const [firstLoad, setFirstLoad] = useState(true)
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  // Scroll the top whenever the page changes
  useEffect(() => {
    window.scrollTo(0, 0)
    ReactGA.initialize(TRACKING_ID);
  }, [])

  return (
    <>
      <Helmet>
        <meta name="description" content="Cornice is the best place to read new writing online." />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <Routes>  
        <Route path='/' element={<LandingPage mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} page={page}   activeStory={activeStory} setActiveStory={setActiveStory} activeStoryContent={activeStoryContent} setActiveStoryContent={setActiveStoryContent} setPage={setPage} domain={domain}/>} />
        <Route path='/read' element={<ContentGrid cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} firstLoad={firstLoad} setFirstLoad={setFirstLoad} mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} page={page} activeStory={activeStory} setActiveStory={setActiveStory} activeStoryContent={activeStoryContent} setActiveStoryContent={setActiveStoryContent} setPage={setPage} domain={domain}/>} />
        <Route path='/search' element={<Search cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} page={page}   activeStory={activeStory} setActiveStory={setActiveStory} activeStoryContent={activeStoryContent} setActiveStoryContent={setActiveStoryContent} setPage={setPage} domain={domain}/>} />
        <Route path='/story/:id' element={<ActiveContent cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} page={page}   activeStory={activeStory} setActiveStory={setActiveStory} activeStoryContent={activeStoryContent} setActiveStoryContent={setActiveStoryContent} setPage={setPage} domain={domain}/>} />
        <Route path='/content/:id' element={<ActiveContent cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} page={page}   activeStory={activeStory} setActiveStory={setActiveStory} activeStoryContent={activeStoryContent} setActiveStoryContent={setActiveStoryContent} setPage={setPage} domain={domain}/>} />
        <Route path='/about' element={<BasicPage cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} targetPage={"about"} page={page}   activeStory={activeStory} setActiveStory={setActiveStory} activeStoryContent={activeStoryContent} setActiveStoryContent={setActiveStoryContent} setPage={setPage} domain={domain}/>} />
        <Route path='/submit' element={<BasicPage cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} targetPage={"submissions"} page={page}   activeStory={activeStory} setActiveStory={setActiveStory} activeStoryContent={activeStoryContent} setActiveStoryContent={setActiveStoryContent} setPage={setPage} domain={domain}/>} />        
        <Route path='/mission' element={<BasicPage cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} targetPage={"mission"} domain={domain}/>} />
        <Route path='/cookie' element={<BasicPage cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} targetPage={"cookie"} domain={domain}/>} />
        <Route path='/contributors' element={<ContributorPage cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} mailLink={mailLink} displaySubscribe={displaySubscribe} setDisplaySubscribe={setDisplaySubscribe} targetPage={"cookie"} domain={domain}/>} />
        <Route path='*' element={<PageNotFound/>} />
      </Routes>
    </>
  );
}


export default memo(App);
