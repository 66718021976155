import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { URLify, getThumbnail } from './Utils';

import {
  Link
} from "react-router-dom";

//<ContentCardMetadata {...props}/>

function ContentCard(props){
  const imageRef = useRef(null)
  const titleRef = useRef(null)

  return(
    <Link to={`/content/${URLify(props.metadata.title)}`} className="ContentCard OffWhiteText">
      <div className="ContentCardContainer" onClick={() => {
          props.setPage("active")
          props.setActiveStory(props.metadata.title)
        }}
        onMouseEnter={()=>{
          imageRef.current.classList.remove("FullOpacity")
          imageRef.current.classList.add("PartialOpacity")
          titleRef.current.classList.add("Underline")
        }}
        onMouseLeave={()=>{
          imageRef.current.classList.add("FullOpacity")
          imageRef.current.classList.remove("PartialOpacity")
          titleRef.current.classList.remove("Underline")
        }}>
        
        <div className="CardImgContainer ">
          <img ref={imageRef} className="CardImg LeftBorder" src={"images/" + getThumbnail(props.metadata.img.replace(/(.jpg|.png)/, "") + ".jpg")}/>    
        </div>
        <div className="FlexColumn FlexCenter ContentCardTitle MarginAuto">
          <div ref={titleRef} className="CardTitle SansSerif HighlightPrimary Uppercase">{props.metadata.title}</div>             
          <div className="CardBlurb DarkText SansSerif">{props.metadata.blurb}</div>  
          <div className="CardByline FlexRow DarkText SansSerif"><div>{props.metadata.author}</div>
          <div>{
            props.metadata.category === "Poetry" 
            ? "Poem" 
            : props.metadata.readTime + " minute read"
          }</div></div>
        </div>     
      </div>        
    </Link>
  )
}

function ContentCardMetadata(props){
  return (
    <div className="CardMetadata FlexRow">
      <div className="FlexColumn">
        <div>{props.metadata.date}</div>
      </div>
      <div className="FlexColumn">
        <div>{props.metadata.readTime} minutes</div>
      </div>
    </div>
  )
}

export default memo(ContentCard)