import React, {memo, forwardRef, useEffect, useState } from 'react';

const FadeInDiv = forwardRef((props, ref) => { 
  const [slow, setSlow] = useState(false)

  useEffect(() => {
    setInterval(() => {
      setSlow(true)
    }, 2000);
  }, [])

  return (
    <div ref={ref} className={`FadeInDiv FullOpacity FullWidth FullHeight FlexCenter AlignCenter TransitionEastOut ${props.speed} ${props.color}`}>
      {props.spinner && slow &&
        <div className="LoadingContainer FlexCenter">
          <div className="LoadingIcon rotate"></div>
        </div>
      }
    </div>   
  )
});

export default FadeInDiv