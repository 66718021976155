import React, { useState, useEffect, useCallback, useRef, memo, forwardRef } from 'react';
import ContentCard from './ContentCard';
import Footer from './Footer';
import LandingMenu from './LandingMenu';
import FormalButton from './FormalButton';
import FeatureCardDeco from './FeatureCardDeco';
import FeatureCardButtons from './FeatureCardButtons';
import FadeInDiv from './FadeInDiv';
import { fadeIn } from './Utils';
import { fadeOut, getThumbnail } from './Utils';
import { removeNudge } from './Utils';
import Nudge from './Nudge';
import Cookies from './Cookies'
import { Helmet } from 'react-helmet';

function ContentGrid(props, ref) {
  const [rowCount, setRowCount] = useState(1)
  const [featureIndex, setFeatureIndex] = useState(0)
  const [stories, setStories] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [featureMetadata, setFeatureMetadata] = useState([{title:""}])

  const currRow = useRef(null)
  const fadeRef = useRef(null)
  const nudgeRef = useRef(null)
  const nudgeRef2 = useRef(null)

  const getMetadata = async () => {
    let res = await fetch(props.domain + 'c?t=short_fiction')
    res = await res.json()
    setStories(res)
    setFeatureMetadata([res[0], res[1], res[2]])
    return res;
  }

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function(resolve,reject) {
        function resolveCallback(){
          resolve();
        }

        const img = new Image();

        img.onload = resolveCallback()
        img.onerror = reject();

        img.src = "images/" + src;
      })
    })

    await Promise.all(promises);
  }

  const shouldCall = useRef(true) // This makes sure we only fetch once, bug with remounts in React 18

  useEffect(() => {
    async function fetchAll(){
      // I'm not relying on state here because if I do, we end up calling fetchImages before state has updated and we can't access stories
      const res = await fetchData()
      await cacheImages([res[0].img, getThumbnail(res[3].img), getThumbnail(res[4].img), getThumbnail(res[5].img)])
      //await cacheImages([res[1].img, res[2].img])
      setIsLoading(false)
      window.scrollTo(0, 0)
    }
    async function fetchData() {
        fadeIn(fadeRef)
        return await getMetadata()
    }
    if (shouldCall.current){
      window.scrollTo(0, 0)
      fetchAll()
      shouldCall.current = false    
    }
  }, [])

  useEffect(()=> {
    
    document.body.style.overflow = "visible";
    if (!isLoading){
      fadeOut(fadeRef)
      setTimeout(() => {      
        removeNudge(nudgeRef)
        removeNudge(nudgeRef2)
      },20)
      cacheImages([stories[1].img, stories[2].img])
    }
  }, [isLoading])

  // Scroll the top whenever the page changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return(
    <>
    <Helmet>
      <meta name="description" content="Cornice is the best place to read new writing online." />
    </Helmet>
    <div className="FullWidth FullHeight OffWhiteBackground">  
        <LandingMenu {...props} MainColor={"DarkPrimary"} SidebarColor={"DarkPrimary"}/>
        <Nudge ref={nudgeRef} speed={"SlowTransition"}/>
        <FadeInDiv spinner={true} ref={fadeRef} color={"OffWhiteBackground"} speed={"SlowTransition"}/>
        <div className='FlexColumn FullWidth'>
          <FeatureCardDeco {...props} featureIndex={featureIndex} setFeatureIndex={setFeatureIndex} metadata={featureMetadata[featureIndex]} setPage={props.setPage} setActiveStory={props.setActiveStory}/>
          <FeatureCardButtons {...props} featureIndex={featureIndex} setFeatureIndex={setFeatureIndex}/>
          <Nudge ref={nudgeRef2} speed={"SlowTransition"}/>
          {[...Array(rowCount)].map((x, i) =>{
            currRow.current = i + 1;
            return <ContentRow key={`row${i}`} {...props} stories={stories} currRow={currRow.current}/>        
          })}
          {(rowCount * 3) + 3 < stories.length &&
          <div className="FullWidth AutoMargin FlexCenter">
            <FormalButton mainColor={"OffWhiteBackground"} text={"More"} style={{marginTop:'20px',marginBottom:'20px'}}callBack={() => {
            setRowCount(rowCount + 1)
          }}/>
          </div>
          }
        </div>
        <Footer {...props}/>
    </div>
    </>
  )
}

function ContentRow(props){
  return(
    <>
      <div className="ContentGrid MainColumnWidth AutoMargin">
        {
        [...Array(3)].map((x, i) =>{
          let index = (props.currRow === 1) ? i : ((props.currRow -1) * 3) + i
          index += 3 // Make sure we skip the first card
          let currStory = (index < props.stories.length) ? props.stories[index] : null
          let lastStory = (index === props.stories.length - 1) ? true : false
          if (currStory){
            if (i === 0 || i === 1){
              return <>
              <ContentCard key={currStory.title} metadata={currStory} setPage={props.setPage} setActiveStory={props.setActiveStory}/>
              <div className="CardDivider"></div>
              </>
            }else{
              return <ContentCard key={currStory.title} metadata={currStory} setPage={props.setPage} setActiveStory={props.setActiveStory}/>
            }
          }
          else {
            return <div className="ContentCard"></div>
          }
        })
        }
      </div>
    </>
  )
}

export default memo(ContentGrid)