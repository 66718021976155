import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { URLify } from './Utils';

import {
  Link, useNavigate
} from "react-router-dom";

function FeatureCardDeco(props){
  const imageRef = useRef(null)
  const titleRef = useRef(null)
  const arrowRef = useRef(null)

  // Position of the pointer when we start swiping
  const [mousePosition, setMousePosition] = useState({x: 0, y: 0})

  const shouldCall = useRef(true)

  const [isSwiping, setIsSwiping] = useState(false)
  const [swipePositionX, setSwipePositionX] = useState(0)
  const [swipePositionY, setSwipePositionY] = useState(0)
  const [endingSwipe, setEndingSwipe] = useState(false)
  const [scrollOffset, setScrollOffset] = useState(0)

  const cycleFeatures = (shift) => {
    const newIndex = props.featureIndex + shift
    if (newIndex < 0){
      props.setFeatureIndex(2)
      return
    }
    if (newIndex > 2){
      props.setFeatureIndex(0)
      return
    }
    props.setFeatureIndex(props.featureIndex + shift)
  }

  // I need to trigger this when mousePosition updates because it was updating asynchronously. If I tried to do this in handleMouseDown the default value would not be overriden in time.
  useEffect(()=>{
    if (mousePosition.x === 0) return;
    setIsSwiping(true)
    const element = imageRef.current
    element.addEventListener('pointermove', handleMouseMovement);
    element.addEventListener('pointerleave', handleMouseMovementEnd);
  },[mousePosition])

  useEffect(() => {
    window.scrollTo(0, scrollOffset)
  }, [scrollOffset])

  // Handle the actual swiping
  // This is triggered as the pointer moves during the swipe
  useEffect(()=>{
    if (!isSwiping) return;

    const element = imageRef.current
    const yOffset = swipePositionY - mousePosition.y
    const xOffset = swipePositionX - mousePosition.x
    // TODO: Only change the scroll offset when the window width is consistent with mobile
    setScrollOffset(yOffset * -1)

    // Check the y axis swipe
    if (Math.abs(yOffset) > 60){
      element.removeEventListener('pointerleave', handleMouseMovementEnd);
      setEndingSwipe(true)
      return
    }
    // Check the x axis swipe
    if (Math.abs(xOffset) > 40){
      handleMouseMovementEnd()
      removeEventListeners()
      setEndingSwipe(true)
      if(xOffset > 0){
        cycleFeatures(-1)
      }else{
        cycleFeatures(1)
      }
    }
  },[swipePositionX, swipePositionX])

  const handleMouseMovement = event => {
    event.preventDefault()
    setSwipePositionX(getMousePositionX(event))
    setSwipePositionY(getMousePositionY(event))
  };

  const removeEventListeners = () => {
    const element = imageRef.current
    if (!element) return
    element.removeEventListener('pointermove', handleMouseMovement);
    element.removeEventListener('pointermove', handleMouseMovement);
    element.removeEventListener('pointerleave', handleMouseMovementEnd);
  }

  const handleMouseMovementEnd = () => {
    setIsSwiping(false)
    removeEventListeners()
  };

  const handleMouseDown = event => {
    if (event.type !== "touchstart" && event.button !== 0) return
    event.preventDefault()
    const x = getMousePositionX(event)
    const y = getMousePositionY(event)
    setMousePosition({x: x, y: y})
  };

  const getMousePositionX = event => {
    if (event.clientX){
      return event.clientX
    }else{
      return event.targetTouches[0] ? event.targetTouches[0].pageX : event.changedTouches[event.changedTouches.length-1].pageX
    }
  }

  const getMousePositionY = event => {
    if (event.clientY){
      return event.clientY
    }else{
      return event.targetTouches[0] ? event.targetTouches[0].pageY : event.changedTouches[event.changedTouches.length-1].pageY
    }
  }

  useEffect(() => {
    const element = imageRef.current

    if (shouldCall.current){
      // Make sure we don't add two event listeners
      element.addEventListener('touchstart', handleMouseDown);
      element.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      removeEventListeners()
      element.removeEventListener('touchstart', handleMouseDown);
      element.removeEventListener('mousedown', handleMouseDown);
    };
  },[])

  // Handle regular clicks
  // We need to make it so that the event listener for touchend updates whenever the metadata changes, which should be different each time a new feature card is cycled

  const navigate = useNavigate();

  const handleMouseUp = (event) => {
    if (event.type !== "touchend" && event.button !== 0) return
    if (!endingSwipe && props.metadata.title !== ''){
      navigate(`/content/${URLify(props.metadata.title)}`)
    }else if(endingSwipe){
      setEndingSwipe(false)
      handleMouseMovementEnd()
    }
  }    

  useEffect(() => {
    const element = imageRef.current
    element.addEventListener('touchend', handleMouseUp);
    element.addEventListener('mouseup', handleMouseUp);

    return() => {
      element.removeEventListener('touchend', handleMouseUp);
      element.removeEventListener('mouseup', handleMouseUp);
    }
  }, [props.metadata, endingSwipe])

  return(
    <>
    <div className="FeatureCardDecoContainer">
    <img src="images/leftarrow.svg" className="FeatureCardArrow LeftAbsolute HighlightPrimary NoMobile Pointer" onClick={() => {cycleFeatures(-1)}}/>
    <img src="images/rightarrow.svg" className="FeatureCardArrow RightAbsolute HighlightPrimary NoMobile Pointer" onClick={() => {cycleFeatures(1)}}/>
    <div style={{backgroundImage: `url('/images/${props.metadata.img}')`}} ref={imageRef} className="FeatureCardDeco FlexColumn Pointer Grabber DarkText FullWidth SansSerif" 
      onMouseEnter={()=>{
        imageRef.current.classList.remove("FullOpacity");
        imageRef.current.classList.add("PartialOpacity");
        titleRef.current.classList.add("Underline");
      }}
      onMouseLeave={()=>{
        imageRef.current.classList.remove("PartialOpacity");
        imageRef.current.classList.add("FullOpacity");
        titleRef.current.classList.remove("Underline");
      }}>
      <div className="FeatureCardFade"></div>
      <div className="FillBlack"></div>
        <div className="FeatureCardContainer FlexColumn">
          <div className="FlexColumn FlexCenter FeatureCardMetaContainer FullHeight">
            <div ref={titleRef} className="FeatureCardTitle HighlightPrimary SansSerif Uppercase TextLeft">{props.metadata.title}</div> 
            <div className="FeatureCardBlurb TextLeft OffWhiteText">{props.metadata.blurb}</div>  
            <div className="FlexRow SpaceBetween">
              <div className="FeatureCardByline TextLeft OffWhiteText">{props.metadata.author}</div> 
              {(props.metadata.category === "Poetry" )
                ? <div className="FeatureCardByline TextLeft OffWhiteText">Poem</div> 
                : <div className="FeatureCardByline TextLeft OffWhiteText">{props.metadata.readTime} minute read</div>  
              }
            </div>
          </div>     
        </div>
    </div>
    </div> 
    </>
  )
}

export default memo(FeatureCardDeco)