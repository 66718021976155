import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

import ActiveContent from './ActiveContent';
import ContentGrid from './ContentGrid';
import About from './About';
import Footer from './Footer';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


function MainContent(props){
  
  // Scroll the top whenever the page changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.page])

  /*
  return(
      <Routes>
        <Route path='/' element={<ContentGrid {...props}/>} />
        <Route path='/active' element={<ActiveContent {...props}/>} />
        <Route path='/about' element={<About {...props}/>} />
      </Routes>
  )*/

  
  return(
    <>
    {props.page == "active" && 
      <ActiveContent {...props}/>
    }
    {props.page === "home" &&
      <>
      <ContentGrid {...props}/>
      <Footer {...props}/>
      </>
    }
    {props.page === "about" &&
      <About/>
    }
    </>
  )
}

export default memo(MainContent)