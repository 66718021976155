export function URLify(string){
  string = string.replace(/\s+/g, "-")
  return string.toLowerCase()
}

export function unURLify(string){
  string = string.replace(/-/g, " ")
  return string
}

export const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const fadeIn = (ref) => {
  if (!ref.current) return;
  ref.current.classList.add("FullOpacity")
  ref.current.classList.remove("NoOpacity")
}

export const fadeOut = (ref) => {
  if (!ref.current) return;
  ref.current.classList.remove("FullOpacity")
  ref.current.classList.add("NoOpacity")
}

export const removeNudge = (ref) => {
  if (!ref.current) return;
  ref.current.classList.remove("TopNudge")
  ref.current.classList.add("NoNudge")
}

export const getThumbnail = (path) => {
  return path.replace(/\.jpg/, "_thumbnail.jpg")
}