import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

import SubscribeDialog from './SubscribeDialog';
import Cross from './Cross';
import Logo from './Logo';

import {
  Routes,
  Route,
  Link
} from "react-router-dom";

function LandingMenu(props){
  const [displayBurger, setDisplayBurger] = useState(false)
  const sideBarRef = useRef(null)
  const [containerCSS, setContainerCSS] = useState("ModernTopBarContent FlexRow MainColumnWidth")
  const [backgroundCSS, setBackgroundCSS] = useState(`ModernTopBar ${props.MainColor}`)

  useEffect(()=>{
    if (props.useGradient) {
      setBackgroundCSS(`${props.MainColor === "OffWhiteBackground" ? "OffWhiteGradientBackground" : "DarkGradientBackground"}`) 
      return
    }
    setBackgroundCSS(props.MainColor)
  },[])

  useEffect(() => {
    try{
      if (displayBurger){
        sideBarRef.current.classList.remove("HiddenSidebar")
      }else{
        sideBarRef.current.classList.add("HiddenSidebar")
      }  
    }catch{

    }
  }, [displayBurger])

  useEffect(() => {
    if (props.landing){
      setContainerCSS("ModernTopBarContent FlexRow MainColumnWidth FlexEnd")
    }else{
      setContainerCSS("ModernTopBarContent FlexRow MainColumnWidth SpaceBetween")
    }
  }, [])

  return(
    <>
    <div className={`ModernTopBar ${props.useGradient ? '' : props.MainColor}`}>
      <div className={containerCSS}>
        {!props.landing &&
          <Logo CSS={"Logo FlexDisplay"}/>
        }     
        {props.landing &&
          <Logo CSS={"Logo NoDisplay"}/>
        }    
        <div className="FlexRow">
          {!displayBurger && 
          <>
            <SearchButton setDisplayBurger={setDisplayBurger}/> 
            <Hamburger setDisplayBurger={setDisplayBurger}/>        
          </>
          }
        </div>
      </div>
      {props.useGradient &&
        <div className={`Gradient ${props.MainColor === "OffWhiteBackground" ? "OffWhiteGradientBackground" : "DarkGradientBackground"}`}/>
      }
    </div>
    <div ref={sideBarRef} className={`LandingSidebar MediumTransition HiddenSidebar ${props.SidebarColor}`}>
      <div className="AutoMargin FullWidth">
        <div className="FlexRow SidebarClose Pointer">
          <Cross height={"45"} width={"45"} callBack={setDisplayBurger}/>
        </div>
        <div className="FlexColumn">
          <div className="SideBarButton Pointer UnderlineHover SansSerif" onClick={() => {
            setDisplayBurger(false);
            props.setDisplaySubscribe(!props.displaySubscribe);
          }}>Support us</div>
          <div style={{height: "30px"}}></div>
          <Link className='SideBarButton Pointer UnderlineHover SansSerif' to="/read" onClick={() => {setDisplayBurger(false)}}>Read</Link>
          <Link className='SideBarButton Pointer UnderlineHover SansSerif' to="/search" onClick={() => {setDisplayBurger(false)}}>Search</Link>
          <Link className='SideBarButton Pointer UnderlineHover SansSerif' to="/submit" onClick={() => {setDisplayBurger(false)}}>Submit</Link>
          <div style={{height: "30px"}}></div>
          <Link className='SideBarButton Pointer UnderlineHover SansSerif' to="/about" onClick={() => {setDisplayBurger(false)}}>About</Link>
          <Link className='SideBarButton Pointer UnderlineHover SansSerif' to="/contributors" onClick={() => {setDisplayBurger(false)}}>Contributors</Link>
        </div>
      </div>
    </div>
    {props.displaySubscribe && <SubscribeDialog {...props}/>}
    </>
  )
}

function SearchButton(props){
  return(
  <Link to={`/search`} className="SearchButtonTopBar FlexCenter Pointer Serif">
  <svg className='ModernHamSVG AutoMargin' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">
    <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"/>
  </svg>
  </Link>   
  )
}


function Hamburger(props){
  return(
    <>
    <div className="ModernHamburger FlexCenter Pointer Serif" onClick={() => {props.setDisplayBurger(true)}}>
      <svg className="ModernHamSVG AutoMargin" viewBox="0 0 100 80" width="30" height="30">
        <rect y="0" width="100" height="11"></rect>
        <rect y="35" width="100" height="11"></rect>
        <rect y="70" width="100" height="11"></rect>
      </svg>
    </div>
    </>
  )
}

function HamburgerMessage(props){
  return(
    <div className="FlexCenter"><div className='AutoMargin GoldText'>{props.message}</div></div>
  )
}




export default memo(LandingMenu)