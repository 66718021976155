import React, { useState, useEffect, useCallback, useRef, memo, forwardRef } from 'react';

import Footer from './Footer';
import TopBar from './TopBar';
import LandingMenu from './LandingMenu';
import { fadeIn, removeNudge } from './Utils';
import { fadeOut } from './Utils';
import FadeInDiv from './FadeInDiv';
import Nudge from './Nudge';
import AuthorProfile from './AuthorProfile';

const headingPattern = /^\#+(?= [^#]+$)/

function ContributorPage (props){
  const [contributors, setContributors] = useState({paras: [""]})
  const fadeInRef = useRef(null)
  const nudgeRef = useRef(null)

  const shouldCall = useRef(true)

  const getContent = useCallback(async () => {
    fadeIn(fadeInRef)
    let res = await fetch(props.domain + `con`)
    res = await res.json()
    setContributors(res) 
    fadeOut(fadeInRef)
    removeNudge(nudgeRef)
  }, [])

  useEffect(() => {
    if (shouldCall.current){
      document.body.style.overflow = "visible";
      getContent()
      window.scrollTo(0, 0)
      shouldCall.current = false
    }
  }, [])

  return(
    <>
    <div className="FullWidth FullHeight">
      <LandingMenu {...props} MainColor="DarkPrimary" SidebarColor="DarkPrimary" landing={false}/>
      <FadeInDiv spinner={false} ref={fadeInRef} color={"OffWhiteBackground"} speed={"SlowTransition"}/>
      <div className="MainColumnWidth ContentContainer OffWhiteBackground">
        {Object.keys(contributors).sort(compare).map((author, i) => {
          if (i === Object.keys(contributors).length - 1){
            return <AuthorProfile author={contributors[author]} name={author} maxWidth={"800px"}/>            
          }else{
            return <><AuthorProfile author={contributors[author]} name={author} maxWidth={"800px"}/><div style={{width:"100%", maxWidth:"850px", margin:"auto", marginTop:"70px", height:"2px", backgroundColor:"var(--HighlightPrimary)"}}></div></>
          }
        })}
      </div>
      <Footer/>
    </div>
    </>
  )
}

const compareStrings = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;

  return 0;
}

const compare = (a, b) => {
  const splitA = a.split(" ");
  const splitB = b.split(" ");
  const lastA = splitA[splitA.length - 1];
  const lastB = splitB[splitB.length - 1];

  return lastA === lastB ?
    compareStrings(splitA[0], splitB[0]) :
    compareStrings(lastA, lastB);
}


export default memo(ContributorPage)