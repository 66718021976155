import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

import { openInNewTab } from './Utils';
import Logo from './Logo';

import {
  Routes,
  Link,
} from "react-router-dom";

function Footer (props){

  return(
    <>
    <div className="Footer DarkPrimary">
      <div className="FooterContent MainColumnWidth FlexColumn">
        <Logo CSS={'FooterLogo'}/>
        <div className="FooterColumns">
          <div className="FlexColumn FooterColumn OffWhiteText">
            <Link className="OffWhiteText" to="/about">About</Link>
            <div className="FooterLink Pointer" onClick={() => openInNewTab('https://www.twitter.com/cornicemag')}>Follow</div>
            <div className="FooterLink Pointer" onClick={() => openInNewTab('https://www.patreon.com/cornice')}>Support</div>
          </div>
          <div className="FlexColumn FooterColumn OffWhiteText">
            <div>The copyright to all contents of this site belongs to Cornice or the individual authors. You may not use the material elsewhere without written permission.</div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default memo(Footer)