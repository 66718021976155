import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

function Cross(props){

  const [CSS, setCSS] = useState("Hamburger FlexCenter Pointer")

  useEffect(() => {
    if (!props.MobileOnly){
      setCSS("FlexCenter Pointer");
    }
  }, []);

  // 45 x 45
  return(
    <>
    <div className={CSS} onClick={() => {props.callBack(false)}}>
      <svg className="HamSVG AutoMargin" width={props.width} height={props.width} fill="currentColor" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>
    </div>
    </>
  )
}

export default memo(Cross)