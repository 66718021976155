import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

import { openInNewTab } from './Utils';
import {
  useNavigate
} from "react-router-dom";
import { URLify } from './Utils';
import { removeNudge } from './Utils';
import { Nudge } from './Nudge'

function AuthorProfile (props){
  const nudgeRef = useRef(null)
  const imageRef = useRef(null)
  const titleRef = useRef(null)
  const [authorClassName, setAuthorClassName] = useState()

  useEffect(() => {
    setTimeout(function() {
      removeNudge(nudgeRef)
    }, 20);
  }, [])


  const navigate = useNavigate();
  const goToPosts = () => navigate({
    pathname:'/search',
    search: `?q=${URLify(props.name)}&n=1`
  });

  return(
    <>
    <div className={`${props.author.image !== 'headshots/none' ? 'Author' : 'AuthorNoImage'} OffWhiteBackground Pointer`}
      onMouseEnter={()=>{
        imageRef.current.classList.remove("FullOpacity");
        imageRef.current.classList.add("PartialOpacity");
        titleRef.current.classList.add("Underline");
      }}
      onMouseLeave={()=>{
        imageRef.current.classList.remove("PartialOpacity");
        imageRef.current.classList.add("FullOpacity");
        titleRef.current.classList.remove("Underline");
      }}>
      {props.author.image !== "headshots/none"
        ? <img className="AuthorHeadshot" src={"../images/" + props.author.image} ref={imageRef} onClick={goToPosts}/>
        : <div className="EmptyAuthorHeadshot" /*style={{width:'150px', height:'150px', margin:'auto', marginTop:'35px'}}*/></div>
      }
      <div className="FlexColumn">
        <div className="AuthorName HighlightPrimary SansSerif" ref={titleRef} onClick={goToPosts}>{props.name} </div>
        <div className="AuthorSummary" onClick={goToPosts}>{props.author.summary}</div>
        <SocialMedia {...props}/>
      </div>
    </div>
    </>
  )
}

function SocialMedia(props){
  const services = ["instagram", "twitter", "linkedin",  "youtube", "tiktok", "github", "facebook", "web", "email"]
  
  return(
    <>
    <div className="FlexRow SocialMedia">
      {services.map(service => {
        const currLink = props.author[service]
        if (currLink !== "none") return <SocialIcon service={service} link={currLink}/>
      })
      }
    </div>
    </>
  )
}

function SocialIcon(props){
  
  return(
    <img className="SocialIcon Pointer" src={`/icons/${props.service}.svg`} onClick={() => {
      let prefix = ''
      if (props.service === 'web'){
        prefix = "http://www."
      }else if (props.service === 'email'){
        prefix = "mailto:"
      }else{
        prefix = `https://www.${props.service}.com/`
      }
      openInNewTab(prefix + props.link)
    }}/>
  )
}

export default memo(AuthorProfile)