import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

import {
  Routes,
  Route,
  Link
} from "react-router-dom";

function FormalButton(props){
  const shouldCall = useRef(true)
  const buttonRef = useRef(null)
  const inflaterRef = useRef(null)

  const handleEnter = () => {
    inflaterRef.current.classList.add("Inflated")
  }

  const handleExit = () => {
    inflaterRef.current.classList.remove("Inflated")
  }

  useEffect(()=>{
    if (shouldCall.current){
      buttonRef.current.addEventListener('pointerenter', handleEnter);
      buttonRef.current.addEventListener('pointerleave', handleExit);
      shouldCall.current = false
    }

    return () => {
      //buttonRef.current.removeEventListener('pointerenter', handleEnter);
      //buttonRef.current.removeEventListener('pointerleave', handleExit);
    };
  }, [])

  return(
    <>
    {props.link &&
    <Link to={props.link} className={`FlexColumn FlexCenter FormalButton Pointer FastTransition SansSerif ${props.mainColor}`}>
      <div ref={buttonRef} className="AutoMargin HighlightPrimary FullWidth FullHeight FlexCenter PositionRelative" onClick={props.callBack}>
        <div className="FastTransition InflaterText">{props.text}</div>
        <div ref={inflaterRef} className="Inflater FastTransition PositionAbsolute"></div>      
      </div>
    </Link>
    }
    {!props.link &&
    <div ref={buttonRef} className="FormalButton Pointer AutoMargin HighlightPrimary FullWidth FullHeight FlexCenter SansSerif PositionRelative" onClick={props.callBack}>
      <div className="FastTransition InflaterText">{props.text}</div>
      <div ref={inflaterRef} className="Inflater FastTransition PositionAbsolute"></div>      
    </div>
    }
    </>
  )
}

export default memo(FormalButton)